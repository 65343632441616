import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    constructor(private datePipe: DatePipe) { }

    formatDate(date: Date | undefined):string | null{
        var val = this.datePipe.transform(date, 'yyyy-MM-dd hh:mm:ss');
        return val != null ? val : "";        
    }
}