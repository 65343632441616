<tds-container>
    <h2>Admin</h2>
    <div>
        <form [formGroup]="testweekForm">
            <div class="tds-row">
                <div class="tds-col-max tds-col-xxlg tds-col-xlg tds-col-lg tds-col-md tds-col-sm tds-col-xs">
                    <div class="input-wrapper">
                        <tds-text-field ngDefaultControl type="Text" size="md" state="default" label="Id"
                            label-position="outside" no-min-width placeholder="Enter id" formControlName="id">
                        </tds-text-field>
                    </div>
                </div>
            </div>
            <div class="tds-row">
                <div class="tds-col-max tds-col-xxlg tds-col-xlg tds-col-lg tds-col-md tds-col-sm tds-col-xs">
                    <div class="input-wrapper">
                        <tds-text-field ngDefaultControl type="Text" size="md" state="default" label="Name"
                            label-position="outside" no-min-width placeholder="Enter name" formControlName="name">
                        </tds-text-field>
                    </div>
                </div>
            </div>
            <div class="tds-row">
                <div class="tds-col-max tds-col-xxlg tds-col-xlg tds-col-lg tds-col-md tds-col-sm tds-col-xs">
                    <div>
                        <tds-button type="submit" variant="primary" size="md" text="Submit"></tds-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</tds-container>