import { Injectable, NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache, from } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { TmtLoggerService } from 'tmt-logger';
import { v4 as uuid } from 'uuid';
import { AppConfigService } from './services/app-config.service';
import { SessionService } from './services/session.service';

export function createApollo(
    appConfigService: AppConfigService,
    httpLink: HttpLink,
    loggerService: TmtLoggerService,
    sessionService: SessionService): Record<string, ApolloClientOptions<any>> {
  let config = appConfigService.getConfig();

  const headerLink = setContext((_, { headers }) => {
    console.debug('setContext');
    let correlationId = uuid();
    let requestId = uuid();
    let sessionId = sessionService.getSessionId() || 'anonymous'
    console.debug("graphql sessionId: " + sessionId)

    return {
      headers: {
        ...headers,
        'x-correlation-id': correlationId,
        'x-request-id': requestId,
        'authorization': sessionId
      }
    }
  });

  const appSyncUri = `${config.appSyncDdb_staging}`;
  const uriLink = httpLink.create({ uri: appSyncUri });

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    console.debug('onError');
    let correlationId = operation.getContext()['headers']['x-correlation-id'];
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, correlationId)
      });
    };

    if (networkError) {
      console.error(`[Network error]: ${JSON.stringify(networkError)}`, correlationId)
      if (networkError.message.includes('401')) {
        let redirectUrl = sessionService.getRedirectUrl() || "empty";
        sessionService.clearSession();
      }

    }
  });

  const timeStartLink = new ApolloLink((operation, forward) => {
    console.debug('forward start');
    let correlationId = operation.getContext()['headers']['x-correlation-id'];
    const startTime = new Date();
    console.log(`[${operation.operationName}] Sending request`, correlationId);
    operation.setContext({ start: startTime });
    console.debug('forward end');
    return forward(operation);
  });

  const timeEndLink = new ApolloLink((operation, forward) => {
    console.debug('ApolloLink')
    return forward(operation).map((data) => {
      let correlationId = operation.getContext()['headers']['x-correlation-id'];
      const duration = new Date().getTime() - operation.getContext()['start'].getTime();
      console.log(`[${operation.operationName}] took ${duration} to complete`, correlationId);
      return data;
    })
  });
  return {
    client: {
      name: 'client',
      link: from([headerLink, timeStartLink, timeEndLink, errorLink, uriLink]),
      cache: new InMemoryCache()
    }
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [AppConfigService, HttpLink, TmtLoggerService, SessionService],
    },
  ],
})

@Injectable()
export class GraphQLModule {
  constructor(private appConfigService: AppConfigService) {
    this.appConfigService.load();
  }
}
