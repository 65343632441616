import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessionService } from './services/session.service';
import { AppConfigService } from './services/app-config.service';
import { LaunchDarklyService } from './services/launchdarkly.service';
import { TmtLoggerService } from 'tmt-logger';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'early-delivery-client';
  public configData: any;
  private subscription: Subscription = new Subscription();
  loggedIn: boolean = false;
  admin: boolean = false;
  test: boolean = false;
  public username: String | null = "";
  public company: String | null = "";
  public authenticated: boolean = false;
  redirectUrl: string | null = null;
  public loginUrl: string = '';


  constructor(
    public config: AppConfigService,
    private ld: LaunchDarklyService,
    private router: Router,
    private loggerService: TmtLoggerService,
    private sessionService: SessionService
  ) {
    this.subscription = this.ld.flagChange.subscribe(() => {
      this.initFlags(ld);
    });

    this.LoginFlow(router)
  }

  LoginFlow(router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let urlParams = new URLSearchParams(
          event.url.substring(event.url.indexOf('?'))
        );
        let errorDescription = urlParams.get('error_description');
        let errorCola = urlParams.get('error');
        let sessionId = urlParams.get('sessionId');
        if (errorCola) {
          console.error(errorDescription || "");
          return;
        }

        // if testing set test variables
        let test = urlParams.get('test');
        if (test == 'true') {
          this.sessionService.setSession("12345678-1234-1234-1234-1234567890aa", "https://tmt.testlinker.devtest.aws.scania.com/")
          this.sessionService.setUserInfo("tester", "SCANIATEST", true)
          this.authenticated = true;
        }
        if (sessionId != null) {
          localStorage.setItem("sessionId", sessionId)
          this.sessionService.setUserInfo(urlParams.get('username') || "Unkown", urlParams.get('company') || "Unkown", true);
          this.username = urlParams.get('username') || "Unkown"
          this.company = urlParams.get('company') || "Unkown"
          this.authenticated = true;
        }
      }
    });
    this.username = this.sessionService.getUsername();
    this.company = this.sessionService.getCompany();
  }

  login() {
    window.location.href = this.loginUrl;
  }

  initFlags(launchDarklyService: LaunchDarklyService) {
    this.admin = launchDarklyService.getFlag('admin');
    this.test = launchDarklyService.getFlag('test');
    this.loggedIn = this.sessionService.isLoggedIn();
  }

  ngOnInit(): void {
    this.configData = this.config as any;
    this.loginUrl = this.config.getLoginUrl();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    // localStorage.clear();
  }
  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event: Event) {
  //   localStorage.clear();
  // }
}
