<p-table
    #dt1
    [value]="deliveriesWithEcu"
    dataKey="Id"
    [rows]="5"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[5, 10, 15]"
    [loading]="loading"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['Id','Name', 'Ecu.EcuName', 'Ecu.EcuId', 'Description', 'Sop.Name', 'TestWeek.Name', 'TestWeek.Id']"
    selectionMode="single"
    [(selection)]="selectedDeliveryWithEcu"
    (onRowSelect)="onRowSelect($event)"
>
    <ng-template pTemplate="caption">
        <!-- <i class="scania-icon-filter"></i>Example Scania filter icon -->
        <div class="flex" style="overflow:hidden">
            <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
            <span class="p-input-icon-left ml-auto" style="float:right">
                <i class="pi pi-search"></i>
                <input pInputText #textInput type="text" (input)="dt1.filterGlobal(textInput.value, 'contains')" placeholder="Search keyword" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="min-width:15rem" pSortableColumn="Id" style="width:20%">
                <div class="flex align-items-center">
                    Id <p-sortIcon field="Id"></p-sortIcon>
                    <p-columnFilter type="text" field="Id" display="menu" [hideOnClear]="true"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem" pSortableColumn="Name" style="width:20%">
                <div class="flex align-items-center">
                    Name <p-sortIcon field="Name"></p-sortIcon>
                    <p-columnFilter type="text" field="Name" display="menu" [hideOnClear]="true"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem" pSortableColumn="Ecu.EcuName" style="width:20%" pTooltip="Electronic Control Unit" tooltipPosition="top">
                <div class="flex align-items-center">
                    ECU <p-sortIcon field="Ecu.EcuName"></p-sortIcon>
                    <p-columnFilter field="Ecu" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect [ngModel]="value" [options]="ecus" placeholder="Any" (onChange)="filter($event.value)" optionLabel="EcuName">
                                <ng-template let-option pTemplate="item">
                                    <div class="inline-block vertical-align-middle">
                                        <span class="ml-1 mt-1">{{ option.EcuName }}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem">
                <div class="flex align-items-center">
                    Description
                    <p-columnFilter type="text" field="Description" display="menu" [hideOnClear]="true"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem" pSortableColumn="Sop.Name" style="width:20%" pTooltip="Start of Production" tooltipPosition="top">
                <div class="flex align-items-center">
                    SOP <p-sortIcon field="Sop.Name"></p-sortIcon>
                    <p-columnFilter field="Sop" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect [ngModel]="value" [options]="sops" placeholder="Any" (onChange)="filter($event.value)" optionLabel="Name">
                                <ng-template let-option pTemplate="item">
                                    <div class="inline-block vertical-align-middle">
                                        <span class="ml-1 mt-1">{{ option.Name }}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem" pSortableColumn="TestWeek.Name" style="width:20%">
                <div class="flex align-items-center">
                    Test Week <p-sortIcon field="TestWeek.Name"></p-sortIcon>
                    <p-columnFilter field="TestWeek" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect [ngModel]="value" [options]="availableTestWeeks" placeholder="Any" (onChange)="filter($event.value)" optionLabel="Name">
                                <ng-template let-option pTemplate="item">
                                    <div class="inline-block vertical-align-middle">
                                        <span class="ml-1 mt-1">{{ option.Name }}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-deliveryWithEcu >
        <tr [pSelectableRow]="deliveryWithEcu">
            <td>
                {{ deliveryWithEcu.Id }}
            </td>
            <td>
                {{ deliveryWithEcu.Name }}
            </td>
            <td>
                {{ deliveryWithEcu.Ecu.EcuName }}
            </td>
            <td>
                <div [innerHTML]="deliveryWithEcu.Description"></div>
            </td>
            <td>
                {{ deliveryWithEcu.Sop.Name }}

            </td>
            <td pTooltip="Start Date: {{ deliveryWithEcu.TestWeek.StartDate }} &#013;&#010; End Date: {{ deliveryWithEcu.TestWeek.EndDate }}">
                {{ deliveryWithEcu.TestWeek.Name }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">No deliveries found</td>
        </tr>
    </ng-template>
</p-table>

<!-- <div>
    <button class="primary-button">primary button</button>
    <button class="secondary-button">secondary button</button>
    <button class="ghost-button">ghost button</button>
    <button class="danger-button">danger button</button>
</div> -->