import { Statement } from '@angular/compiler';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscription, switchMap } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { deliveryQueries } from 'src/app/graphql/queries/deliveryQueries';
import { Delivery } from 'src/app/models/delivery';
import { UtilsService } from 'src/app/services/utils.service';
import { TmtLoggerService } from 'tmt-logger';

@Component({
  selector: 'app-sw-delivery',
  templateUrl: './sw-delivery.component.html',
  styleUrls: ['./sw-delivery.component.scss']
})
export class SwDeliveryComponent {
  deliveryId: String = '';
  delivery!: Delivery;
  private subscription!: Subscription;

  deliveryForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    validFrom:  [''],
    sop:  [''],
    ecuId:  ['']
  });

  constructor(private apollo: Apollo, private logger: TmtLoggerService, private config: AppConfigService, 
    private route :ActivatedRoute, private router: Router, private fb: FormBuilder, private utilsService: UtilsService) {
  }

  ngOnInit() {

    //TODO check if url contains id, then update else new
    this.subscription = this.route.url.pipe(switchMap(urlSegment =>
      this.deliveryId = (urlSegment[1].path)
      )).subscribe (deliveryId => {
        this.getDelivery();
      })
  }

  getDelivery() {
    this.apollo.use('client')
    .watchQuery({
      query: deliveryQueries.getDelivery,
      variables: {deliveryId: this.deliveryId},          
      errorPolicy: "all"
    }
    )
    .valueChanges
    .subscribe((result: any) => {
      this.delivery = result.data?.getDelivery; 
      this.updateForm(this.delivery);
    });
  }

  updateForm(delivery: Delivery | undefined) {
    this.deliveryForm.patchValue({
      name: delivery?.Name,
      description: delivery?.Description,
      validFrom: this.utilsService.formatDate(delivery?.ValidFrom),
      sop: delivery?.Sop,
      ecuId: delivery?.EcuId    
    });
  }

  public onSubmit()
  {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
