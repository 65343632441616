import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TestWeek } from '../../../models/testCalendar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit{
  testweekForm = this.fb.group({
    name: ['', Validators.required],
    id: ['']
  })

  constructor(private fb: FormBuilder){}

  ngOnInit(): void {
    let max = 99;
    let min = 0;
    let rnd = Math.floor(Math.random() * (max - min + 1)) + min;
    this.testweekForm.patchValue({
      id: '8c625295-1334-4bfb-ac5b-d66bdd443d40',
      name: 'Tw2345_' + rnd.toString()
    })
  }

  public onSubmit()
  {
    let tw: TestWeek = {
      Id: (this.testweekForm.value.id != null) ? this.testweekForm.value.id : '', 
      Name: (this.testweekForm.value.name != null) ? this.testweekForm.value.name : '', 
      StartUtc: new Date('2023-11-06 08:00:00'), 
      EndUtc: new Date('2023-12-01 23:59:59')}
  }

}
