import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeliveriesComponent } from './components/delivery/deliveries/deliveries.component';
import { FeatureFlagsComponent } from './components/delivery/featureFlags/feature-flags.component';
import { SwDeliveryComponent } from './components/delivery/sw-delivery/sw-delivery.component';
import { SettingsComponent } from './components/settings/settings/settings.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DeliveriesComponent
      },
      {
        path: 'software-delivery/:id',
        component: SwDeliveryComponent
      }
    ]
  },
  {
    path: 'deliveries',
    children: [
      {
        path: '',
        component: DeliveriesComponent
      },
      {
        path: 'software-delivery',
        component: SwDeliveryComponent
      },
      {
        path: 'feature-flags',
        component: FeatureFlagsComponent
      }
    ]
  },
  {
    path: 'settings',
    children: [
      {
        path: '',
        component: SettingsComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
