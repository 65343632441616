import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { testCalendarQueries } from '../graphql/queries/testCalendarQueries';
import { TestCalendar, TestWeek } from '../models/testCalendar';
import { testWeekMutations } from '../graphql/mutations/testWeekMutations';
@Injectable({
  providedIn: 'root'
})

export class TestCalendarService {
  public TestWeeks: TestCalendar[] = [];
  public TestWeekSubject: Subject<TestCalendar[]> = new BehaviorSubject<TestCalendar[]>([]);
  public SOPs: TestCalendar[] = [];
  public SOPSubject: Subject<TestCalendar[]> = new BehaviorSubject<TestCalendar[]>([]);

  constructor(private apollo: Apollo) {
    this.getAllTestWeeks();
    this.getAllSOPs();
  }

  getAllTestWeeks() {
    []
  }

  updateTestWeek(testWeek: TestWeek) {
    this.apollo.use('client')
      .mutate({
        mutation: testWeekMutations.addOrUpdateTestWeek,
        variables: {
          id: testWeek.Id,
          name: testWeek.Name,
          validFrom: testWeek.StartUtc,
          validTo: testWeek.EndUtc
        },
      })
      .subscribe(
        (data) => {
          let updatedTw: any = data;
          this.TestWeekSubject.next(Object.assign([], testWeek));
        }
      );
  }

  getTestWeekByStartDate(selectedDate: Date): TestCalendar {
    const validFrom = new Date(selectedDate)
    return this.TestWeeks.find(tw => new Date(tw.StartDate) <= validFrom&& new Date(tw.EndDate) >= validFrom) ?? <TestCalendar>{};
  }

  getAllSOPs() {
    []
  }
}
