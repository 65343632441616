import { gql } from 'apollo-angular';

export class featureFlagQueries {

  public static getFeatureFlagQuery = gql`
    query getFeatureFlags {
      getFeatureFlags
    }
    `
};



