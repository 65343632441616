import { gql } from 'apollo-angular';

export class deliveryQueries {

  public static getAllDeliveriesQuery = gql`
    query getAllDeliveries {
      getAllDeliveries {
        Description
        Id
        Name
        ValidFrom
        EcuId
        Sop
      }
    }
    `

  public static getDeliveriesQuery = gql`
    query getDeliveries ($validFrom:String!){
      getDeliveries(Filter: {FilterItems: {Key: "ValidFrom", Value: $validFrom}}) {
        Description
        Id
        Name
        ValidFrom
        EcuId
        Sop
      }
    }
    `

  public static getDelivery = gql`
  query getDelivery($deliveryId:ID!) {
    getDelivery(Id: $deliveryId) {
      Description
      Id
      Name
      ValidFrom
      EcuId
      Sop
    }
  }
  `

  public static getDeliveryAndEcusByScan = gql`
  query getDeliveryAndEcus {
    getAllDeliveries {
      Description
      Id
      Name
      ValidFrom
      EcuId
      Sop
    }
    getAllEcus {
      EcuId
      EcuName
    }
  } 
  `

  public static getDeliveryAndEcus = gql`
  query getDeliveryAndEcus ($validFrom:String!){
      getDeliveries(Filter: {FilterItems: {Key: "ValidFrom", Value: $validFrom}}) {
        Description
        Id
        Name
        ValidFrom
        EcuId
        Sop
      }
      getAllEcus {
        EcuId
        EcuName
      }
    }
    `

};



