<p-card class="flex justify-content-center">
    <form [formGroup]="deliveryForm" (ngSubmit)="onSubmit()">
        <h2>Delivery with id {{delivery?.Id}}</h2>
        <div class="row">
            <div class="p-float-label">
                <input type="text" pInputText formControlName="name" />
                <label for="name">Name</label>
            </div>
        </div>
        <div class="row space-above">
            <div class="p-float-label">
                <textarea rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
                <label for="description">Description</label>
            </div>
        </div>
        <div class="row space-above">
            <div class="p-float-label">
                <input type="text" pInputText formControlName="validFrom" />
                <label for="validFrom">Valid from</label>
            </div>
        </div>
        <div class="row  space-above">
            <div class="p-float-label">   
                <input type="text" pInputText formControlName="sop" />
                <label for="sop">SOP</label>
            </div>
        </div>
        <div class="row  space-above">
            <div class="p-float-label">
                <input type="text" pInputText formControlName="ecuId" />
                <label for="ecuId">ECU id</label>
            </div>
        </div>
    </form>
</p-card>