import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SwDeliveryComponent } from 'src/app/components/delivery/sw-delivery/sw-delivery.component';
import { ErrorHandlerService, LOGGER_CONFIG } from 'tmt-logger';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SettingsComponent } from './components/settings/settings/settings.component';
import { GraphQLModule } from './graphql.module';
import { LaunchDarklyService } from './services/launchdarkly.service';
import { TableModule } from 'primeng/table';
import { ToastModule} from 'primeng/toast';
import { CalendarModule} from 'primeng/calendar';
import { SliderModule} from 'primeng/slider';
import { MultiSelectModule} from 'primeng/multiselect';
import { ContextMenuModule} from 'primeng/contextmenu';
import { DialogModule} from 'primeng/dialog';
import { ButtonModule} from 'primeng/button';
import { DropdownModule} from 'primeng/dropdown';
import { ProgressBarModule} from 'primeng/progressbar';
import { InputTextModule} from 'primeng/inputtext';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DeliveriesComponent } from './components/delivery/deliveries/deliveries.component';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    SwDeliveryComponent,
    DeliveriesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ProgressBarModule,
    InputTextModule,
    BrowserAnimationsModule
  ],
  providers: [
    LaunchDarklyService,
    DatePipe,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: LOGGER_CONFIG,
      useFactory: (appConfigService: AppConfigService) => appConfigService.logConfig$,
      deps: [AppConfigService]
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
