import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { featureFlagQueries } from '../../../graphql/queries/featureFlagsQueries';


@Component({
  selector: 'app-feature-flags',
  templateUrl: './feature-flags.component.html',
  styleUrls: ['./feature-flags.component.scss']
})
export class FeatureFlagsComponent implements OnInit{
  configs: string = "empty";
  loading = true;
  error: any;

  constructor(private apollo: Apollo) {}

   ngOnInit() {
    this.apollo
      .watchQuery({
        query: featureFlagQueries.getFeatureFlagQuery,
        errorPolicy: "all"
      }
      )
      .valueChanges.subscribe((result: any) => {
        this.configs = result.data?.getFeatureFlags;
        this.loading = result.loading;
        this.error = result.error;
      });
  }

}
