import { Injectable } from '@angular/core';
import { Subject, shareReplay } from 'rxjs';
import { EnvironmentConfig } from 'tmt-logger';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private logConfigSubject = new Subject<EnvironmentConfig>()
  public logConfig$ = this.logConfigSubject.asObservable().pipe(shareReplay(1))
  private configuration: any

  constructor() { }

  load() {
    this.configuration = environment
    this.logConfigSubject.next(this.configuration.logConfig)
  }

  getConfig(): any {
    return this.configuration
  }

  private featureToggles: { [key: string]: boolean } = {
    newFeature: true
  };
  isEnabled(feature: string): boolean {
    return this.featureToggles[feature] || false;
  }

  getLoginUrl(): string {
    console.log(`LoginUrl=${this.getConfig().auth_url}?origin=${`${window.location.protocol}//${window.location.host}`}`)
    return `${this.getConfig().auth_url}/?origin=${`${window.location.protocol}//${window.location.host}`}`;
  }
}
