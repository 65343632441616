import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private _username: string | null = null;
  private _company: string | null = null;
  private _loggedIn: boolean = false;
  private _sessionId: string | null = null;
  private _redirectUrl: string | null = null;

  constructor() { }

  setSession(sessionId: string, redirect_url: string) {
    this._sessionId = sessionId;
    this._redirectUrl = redirect_url
    localStorage.setItem('sessionId', sessionId);
    localStorage.setItem('redirect_url', redirect_url);
  }
  //session management
  setUserInfo(username: string, company: string, loggedIn: boolean): void {
    this._username = username;
    this._company = company;
    this._loggedIn = loggedIn;
    localStorage.setItem('company', company);
    localStorage.setItem('username', username);
    localStorage.setItem('loggedIn', String(loggedIn));
  }
  getSessionId(): string | null {
    return this._sessionId || localStorage.getItem('sessionId');
  }

  getRedirectUrl(): string | null {
    return this._redirectUrl || localStorage.getItem('redirect_url');
  }

  getUsername(): string | null {
    return this._username || localStorage.getItem('username');
  }

  getCompany(): string | null {
    return this._company || localStorage.getItem('company');
  }

  isLoggedIn(): boolean {
    return this._loggedIn || localStorage.getItem('loggedIn') === 'true';
  }

  clearSession(): void {
    this._sessionId = null;
    this._username = null;
    this._company = null;
    this._loggedIn = false;
    this._redirectUrl = null

    // Clear data from local storage
    localStorage.removeItem('sessionId');
    localStorage.removeItem('username');
    localStorage.removeItem('company');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('redirect_url');
  }
}
