<script>
  /* For demonstration purposes only. Do this in the preferred way of your framework instead. */
  window.demoSideMenu = document.querySelector("#demo-side-menu");
  window.demoHamburger = document.querySelector("#demo-hamburger");
</script>
<style>

  /* Note: to make the layout fill the entire viewport height you'll need to set the */
  /* height of the parent element and all of its ancestors to 100%. */
  /* Please note that using 'vh' for this can cause issues on some mobile browsers. */
  .demo-layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .demo-main {
    flex-grow: 1;
  }

  /* If an extra button in the Header is required except on
  very narrow screens, you can use classes like these: */
  .demo-hide {
    display: none;
  }

  @media (min-width: 375px) {
    .demo-xs-hide {
      display: none;
    }
    .demo-xs-show {
      display: block;
    }
  }
  @media (min-width: 992px) {
    .demo-lg-show {
      display: block;
    }
  }
</style>

<div class="demo-layout">
  <tds-header>
    <tds-header-title>Delivery Database</tds-header-title>

    <tds-header-dropdown>
      <span slot="label" value="deliveries">Deliveries</span>
      <tds-header-dropdown-list>
        <tds-header-dropdown-list-item>
          <a href="deliveries">Overview</a>
        </tds-header-dropdown-list-item>
        <tds-header-dropdown-list-item>
          <a href="https://tegel.scania.com">Rim wheel</a>
        </tds-header-dropdown-list-item>
      </tds-header-dropdown-list>
    </tds-header-dropdown>
    <tds-header-item>
      <a href="deliveries/software-delivery">New delivery</a>
    </tds-header-item>
    <tds-header-dropdown *ngIf="(admin)">
      <span slot="label" value="deliveries">Admin</span>
      <tds-header-dropdown-list>
        <tds-header-dropdown-list-item>
          <a href="settings" value="settings">Settings</a>
        </tds-header-dropdown-list-item>
      </tds-header-dropdown-list>
    </tds-header-dropdown>

    <tds-header-launcher slot="end" aria-label="Application launcher" value="launcher">
      <tds-header-launcher-grid-title>Deliveries</tds-header-launcher-grid-title>
      <tds-header-launcher-grid>
        <tds-header-launcher-grid-item>
          <a href="deliveries" value="software_launcher">
            <tds-icon name="truck" size="32"></tds-icon>
            Software
          </a>
        </tds-header-launcher-grid-item>
        <tds-header-launcher-grid-item>
          <a href="https://tegel.scania.com">
            <tds-icon name="star" size="32"></tds-icon>
            Tegel
          </a>
        </tds-header-launcher-grid-item>
      </tds-header-launcher-grid>
      <tds-header-launcher-grid-title *ngIf="(admin)">Admin</tds-header-launcher-grid-title>
      <tds-header-launcher-grid *ngIf="(admin)">
        <tds-header-launcher-grid-item>
          <a href="settings" value="settings">
            <tds-icon name="star" size="32"></tds-icon>
            Settings
          </a>
        </tds-header-launcher-grid-item>
      </tds-header-launcher-grid>
    </tds-header-launcher>
    <tds-header-dropdown
        slot="end"
        no-dropdown-icon
        class="user-dropdown"
      >
        <div slot="icon">
          <img
            src="https://www.svgrepo.com/show/384671/account-avatar-profile-user-14.svg"
            alt="User menu."
          />
          <tds-badge size="sm"></tds-badge>
        </div>
        <tds-header-dropdown-list size="lg">
          <tds-header-dropdown-list-user
          *ngIf="loggedIn"
          header="{{ username }}"
          subheader="{{ company }}"
          ></tds-header-dropdown-list-user>
          <tds-header-dropdown-list-item
          *ngIf="!loggedIn">
            <a (click)="login()">
              <tds-icon name="login"></tds-icon>
              <div class="tds-u-pl1">Login</div>
            </a>
          </tds-header-dropdown-list-item>
        </tds-header-dropdown-list>
      </tds-header-dropdown>

    <tds-header-brand-symbol slot="end">
      <a aria-label="Scania - red gryphon on blue shield" href="https://scania.com"></a>
    </tds-header-brand-symbol>
  </tds-header>

  <tds-side-menu id="demo-side-menu" aria-label="Side menu">
    <tds-side-menu-overlay
      slot="overlay"
      onclick="demoSideMenu.open = false;demoHamburger.setAttribute('aria-expanded', false);"
    ></tds-side-menu-overlay>

    <tds-side-menu-close-button
      slot="close-button"
      onclick="demoSideMenu.open = false;demoHamburger.setAttribute('aria-expanded', false);"
    ></tds-side-menu-close-button>

    <tds-side-menu-item>
      <button>
        <tds-icon name="info" size="24"></tds-icon>
        About
      </button>
    </tds-side-menu-item>

    <tds-side-menu-item>
      <a href="https://www.scania.com">
        <tds-icon name="truck" size="24"></tds-icon>
        Truck types
      </a>
    </tds-side-menu-item>

    <tds-side-menu-dropdown>
      <tds-icon slot="icon" name="tool" size="24"></tds-icon>
      <span slot="label">Wheel types</span>
      <tds-side-menu-dropdown-list>
        <tds-side-menu-dropdown-list-item>
          <a href="https://design.scania.com">Hub-centric wheel</a>
        </tds-side-menu-dropdown-list-item>
        <tds-side-menu-dropdown-list-item>
          <a href="https://design.scania.com">Rim wheel</a>
        </tds-side-menu-dropdown-list-item>
      </tds-side-menu-dropdown-list>
    </tds-side-menu-dropdown>

    <tds-side-menu-item slot="end" onclick="alert('Calendar button clicked')">
      <button>
        <tds-icon name="calendar" size="24px"></tds-icon>
        Calendar
      </button>
    </tds-side-menu-item>

    <tds-side-menu-dropdown slot="end" class="demo-lg-hide" selected>
      <tds-side-menu-user
        slot="label"
        heading="Name Namesson"
        subheading="Scania"
        img-src="https://www.svgrepo.com/show/384671/account-avatar-profile-user-14.svg"
        img-alt=""
      ></tds-side-menu-user>
      <tds-side-menu-dropdown-list>
        <tds-side-menu-dropdown-list-item selected>
          <a href="https://design.scania.com">My Instructions</a>
        </tds-side-menu-dropdown-list-item>
        <tds-side-menu-dropdown-list-item>
          <a href="https://design.scania.com">Task List</a>
        </tds-side-menu-dropdown-list-item>
      </tds-side-menu-dropdown-list>
    </tds-side-menu-dropdown>
  </tds-side-menu>

  <main class="demo-main tds-u-p3" style="box-sizing: border-box;">
    <router-outlet></router-outlet>
    <app-messages></app-messages>
  </main>
</div>
