import { gql } from 'apollo-angular';

export class testWeekMutations {

  public static addOrUpdateTestWeek = gql`
    mutation MyMutation($id:ID!, $name:String, $validFrom:String, $validTo:String) {
        createTestWeek(input: {EndUtc: $validTo, Id: $id, Name: $name, StartUtc: $validFrom}){
            Name
            Id
            StartUtc
            EndUtc            
        }
    }`;
}