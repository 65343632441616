import { Component, OnInit, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AppConfigService } from 'src/app/services/app-config.service';
import { deliveryQueries } from 'src/app/graphql/queries/deliveryQueries';
import { TmtLoggerService } from 'tmt-logger';
import { Table } from 'primeng/table';
import { Delivery } from 'src/app/models/delivery';
import { TestCalendar } from 'src/app/models/testCalendar';
import { ActivatedRoute, Router } from '@angular/router';
import { TestCalendarService } from 'src/app/services/testcalendar.service';


@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss']
})
export class DeliveriesComponent implements OnInit {
  deliveries: Delivery[] = [];
  ecus: any[] = [];
  sops: TestCalendar[] = [];
  deliveriesWithEcu: any[] = [];
  loading: boolean = false;
  @ViewChild('dt') dt: Table | undefined;
  selectedDeliveryWithEcu!: any;
  availableTestWeeks: TestCalendar[] = [];

  constructor(private apollo: Apollo, private logger: TmtLoggerService, private testCalendarService: TestCalendarService,
    private config: AppConfigService, private router: Router) {
    //this.startTime = window.performance.now()
  }

  ngOnInit() {
    this.getDeliveries();
  }

  getDeliveries() {
    this.loading = true;
    this.apollo.use('client')
      .watchQuery({
        query: deliveryQueries.getDeliveryAndEcus,
        variables: { validFrom: "2022-08-14 08:00:00" },
        errorPolicy: "all"
      }
      )
      .valueChanges.subscribe((result: any) => {
        console.log(result.data);
        this.deliveries = result.data?.getDeliveries;
        this.ecus = result.data?.getAllEcus.slice().sort((a, b) => a.EcuName.toLowerCase().localeCompare(b.EcuName.toLowerCase()));
        this.deliveriesWithEcu = this.deliveries?.map((delivery: Delivery) => {
          return {
            Id: delivery.Id,
            Name: delivery.Name,
            Description: delivery.Description?.length > 100 ? delivery.Description?.substring(0, 100) + "..." : delivery.Description,
            Sop: this.sops.find((sop) => sop.Name === delivery.Sop) ?? { Name: delivery.Sop, Id: null, TypeId: 6, Description: null, StartDate: null, EndDate: null},
            Ecu: this.ecus.find((ecu) => ecu.EcuId === delivery.EcuId) ?? { EcuId: delivery.EcuId, EcuName: null },
            TestWeek: []
          };
        })
        console.log(this.deliveriesWithEcu);

        this.sops = this.testCalendarService.SOPs.slice().sort((a, b) => b.Name.toLowerCase().localeCompare(a.Name.toLowerCase()));
        this.availableTestWeeks = this.testCalendarService.TestWeeks.slice().sort((a, b) => { if (a.StartDate < b.StartDate) { return 1; } if (a.StartDate > b.StartDate) { return -1; } return 0; })
        this.loading = false;
      });
  }

  onRowSelect(event: any) {
    console.log(event.data.Id);
    this.router.navigate(['software-delivery/' + event.data.Id])
  }

  clear(table: Table) {
    table.clear();
  }
}
